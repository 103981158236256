import "./App.css";
import { useState, useEffect } from "react";
import Calendar from "./Header/DatePick.js";
import ImageGrid from "./Grid/ImageGrid.js";
import ImageGridList from ".";
import Header from "./Header/Header.js";
import { useAuth0 } from "@auth0/auth0-react";
import { Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { createTheme } from "@mui/material";
import Home from "./Home";

function App() {
  const { isLoading, loginWithRedirect, user } = useAuth0();

  useEffect(() => {
    (async function login() {
      if (!isLoading && !user) {
        await loginWithRedirect();
      }
    })();
  }, [isLoading]);
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<ProtectedRoute component={Home} />}
      ></Route>
    </Routes>
  );
}

export default App;
