import Header from "./Header/Header";
import ImageGrid from "./Grid/ImageGrid";
import { useState } from "react";
function Home() {
  const [objectList, setObjectList] = useState([]);
  const [headers, setHeaders] = useState(null);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Header
        setDataNotFound={setDataNotFound}
        setHeaders={setHeaders}
        headers={headers}
        objectList={objectList}
        setObjectList={setObjectList}
        loading={loading}
        setLoading={setLoading}
      ></Header>
      <ImageGrid
        dataNotFound={dataNotFound}
        headers={headers}
        objectList={objectList}
        loading={loading}
      ></ImageGrid>
    </>
  );
}

export default Home;
