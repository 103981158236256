import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

function HeaderCell({ item }) {
  const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      padding: "12px 0px",
      fontSize: 18,
      [theme.breakpoints.down("lg")]: {
        fontSize: 14,
        padding: "8px 0px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "4px 0px",
        fontSize: 12,
      },
    },
  }));
  return (
    <StyledHeaderCell
      className="header-cell"
      align="center"

      sx={{
        color: "white",
      }}
    >
      {item}
    </StyledHeaderCell>
  );
}

export default HeaderCell;
