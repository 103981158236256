import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useEffect } from "react";

function DataNotFound({ message }) {
  return (
    <div className=" empty-data-message-container">
      <div className="fade-in-fast" id="empty-data-message">
        <ErrorOutlineIcon id="no-grid-data-icon" />
        <p>{message}</p>
      </div>
    </div>
  );
}

export default DataNotFound;
